/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { GetServerSideProps, GetServerSidePropsContext, GetServerSidePropsResult, NextPage } from 'next';
import Head from 'next/head';
import { AxiosError, AxiosResponse } from 'axios';

import { useDidomiNoticeStore } from '@/store/useDidomiNoticeStore';
import { DataItem, getMainPosts, getPostsByLikes } from '@/api/posts';
import { ICategoryData } from '@/api/categories/interfaces';
import { ITag } from '@/api/tags/interfaces';
import { getSingleTag } from '@/api/tags/tags';
import { getCategories } from '@/api/categories/categories';
import { getTagScripts } from '@/api/tagscripts/tagscripts';
import { sortCategories } from '@/helpers/sortCategories';
import { PageFeedTemplate } from '@/components/templates/PageFeedTemplate';
import { useAdPageName } from '@/hooks/useAdPageName';
import { AdsSetup } from '@/components/organisms/AdCompanion';
import { getRemoteConfigValue } from '@/firebase/remoteConfig';
import { TagScript } from '@/api/tagscripts/interfaces';
import Script from 'next/script';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const appInsights = require('@azure');

const DECATHLON_TOKEN = process.env.DECATHLON_TOKEN as string;

interface HomePageProps {
  posts: DataItem[];
  favouritePosts: DataItem[];
  categoriesData: ICategoryData[];
  tagScriptsData: {
    headTags: TagScript[];
    bodyTags: TagScript[];
  };
  pageCount: number;
  singleTag: ITag | null;
  error?: any;
}

export const getServerSideProps: GetServerSideProps<HomePageProps> = async (
  context: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<HomePageProps>> => {
  context.res.setHeader('Cache-Control', 'max-age=0');

  try {
    const { query } = context;
    const { page, tag, tagId, top } = query;
    const pageNumber = page || 1;
    const [mainResponse, favouriteResponse, singleTagResponse, categoriesResponse, tagScriptsData] = await Promise.all([
      getMainPosts(pageNumber as number, tag as string, top as string),
      getPostsByLikes('24h'),
      getSingleTag(tagId as string),
      getCategories(),
      getTagScripts(),
    ]);

    const mainData = mainResponse.data;
    const favouritePosts = favouriteResponse.data.data;
    const { pageCount } = mainData.meta.pagination;
    const singleTag = singleTagResponse?.data ?? null;
    const categoriesData = sortCategories(categoriesResponse.data);

    return {
      props: {
        posts: mainData.data,
        favouritePosts,
        pageCount,
        singleTag,
        categoriesData,
        tagScriptsData,
      },
    };
  } catch (error) {
    console.log(error);
    const { response } = error as AxiosError;
    const { status } = response as AxiosResponse;

    if (status !== 404) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      appInsights.defaultClient.trackException({ exception: error });
    }

    return {
      props: {
        posts: [],
        favouritePosts: [],
        pageCount: 0,
        singleTag: null,
        categoriesData: [],
        tagScriptsData: {
          headTags: [],
          bodyTags: [],
        },
      },
    };
  }
};

const HomePage: NextPage<HomePageProps> = (props) => {
  const { getAdPageName } = useAdPageName();
  const pageName = getAdPageName();
  const isNoticeReady = useDidomiNoticeStore((state) => state.isNoticeReady);
  const isProductionENV = process.env.ENV_TYPE === 'prod';

  const headTags = props.tagScriptsData.headTags.map((tag) => {
    if (tag.attributes.tagType === 'favicon') return <link rel="icon" href={String(tag.attributes.tagSource)} />;
    if (tag.attributes.tagType === 'script') {
      return (
        <script
          id={tag.attributes.tagId ? tag.attributes.tagId : String(tag.id)}
          dangerouslySetInnerHTML={{ __html: tag.attributes.Tag ? tag.attributes.Tag : '' }}
          src={tag.attributes.tagSource ? tag.attributes.tagSource : undefined}
          async={tag.attributes.async ? tag.attributes.async : undefined}
          defer={tag.attributes.defer ? tag.attributes.defer : undefined}
          crossOrigin={tag.attributes.crossOrigin ? tag.attributes.crossOrigin : undefined}
        />
      );
    }
    return (
      <div
        className={tag.attributes.tagClass ? tag.attributes.tagClass : ''}
        id={tag.attributes.tagId ? tag.attributes.tagId : ''}
        dangerouslySetInnerHTML={{ __html: tag.attributes.Tag ? tag.attributes.Tag : '' }}
      />
    );
  });

  const bodyTags = props.tagScriptsData.bodyTags.map((tag) => {
    if (tag.attributes.tagType === 'favicon') return <link rel="icon" href={String(tag.attributes.tagSource)} />;
    if (tag.attributes.tagType === 'script') {
      return (
        <Script
          id={tag.attributes.tagId ? tag.attributes.tagId : String(tag.id)}
          dangerouslySetInnerHTML={{ __html: String(tag.attributes.Tag) }}
          src={tag.attributes.tagSource ? tag.attributes.tagSource : undefined}
          async={tag.attributes.async ? tag.attributes.async : undefined}
          defer={tag.attributes.defer ? tag.attributes.defer : undefined}
          crossOrigin={tag.attributes.crossOrigin ? tag.attributes.crossOrigin : undefined}
        />
      );
    }
    return (
      <div
        className={tag.attributes.tagClass ? tag.attributes.tagClass : ''}
        id={tag.attributes.tagId ? tag.attributes.tagId : ''}
        dangerouslySetInnerHTML={{ __html: tag.attributes.Tag ? tag.attributes.Tag : '' }}
      />
    );
  });

  return (
    <>
      {isNoticeReady && <AdsSetup getRemoteConfigValue={getRemoteConfigValue} pageId={1878515} pageName={pageName} />}
      <Head>
        <title>Fangol.pl - Piłka w skrócie</title>
        <meta
          name="description"
          content="Fangol.pl - piłka w skrócie. Newsy, memy i relacje z meczów. Przeglądaj codziennie aktualności ze świata futbolu. Oceniaj i komentuj wrzuty, głosuj w ankietach, rozwiązuj nieprzeciętne piłkarskie quizy."
        />
        <meta name="convertiser-verification" content={DECATHLON_TOKEN} />
        {headTags}
        {!isProductionENV && <meta name="robots" content="noindex" />}
      </Head>
      <PageFeedTemplate {...props} showFilterPanel />
      <div>{bodyTags}</div>
    </>
  );
};

export default HomePage;
